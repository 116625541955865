
import twemoji from "twemoji";

import "./index.scss"

twemoji.parse(document.querySelector(".has-emoji"), {

    className: "emoji"

})
